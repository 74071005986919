import React, { ReactElement } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import { ACCEPT, ORDER_RESERVE_ERROR_MODAL_TEXT, ORDER_RESERVE_ERROR_MODAL_TITLE } from '../../../i18n/translations/TR';

interface OrderLineReserveErrorModalProps {
    showModal: boolean
    closeModal: () => void
}

export const OrderLineReserveErrorModal = (
    {
        showModal,
        closeModal,
    }: OrderLineReserveErrorModalProps,
): ReactElement => {
    const { t } = useTranslation();
    return (
        <Modal
            onHide={closeModal}
            show={showModal}
            className="modal fade shopping-modal"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <h5 className="modal-title">{t(ORDER_RESERVE_ERROR_MODAL_TITLE)}</h5>
            </Modal.Header>
            <Modal.Body>
                <div>{t(ORDER_RESERVE_ERROR_MODAL_TEXT)}</div>
            </Modal.Body>
            <Modal.Footer>
                <button
                    type="submit"
                    form="add-form"
                    className="btn btn-primary"
                    onClick={closeModal}
                >
                    {t(ACCEPT)}
                </button>
            </Modal.Footer>
        </Modal>
    );
};
