/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DISCOUNT, M2_SEND_TO_CART, PIECE_UNIT, PIECE_UNIT_SINGLE, UNIT_SEND_TO_CART } from 'i18n/translations/TR';
import { getSquareMeasurement } from 'app/shared/getSquareMeasurement';
import { getRoundedValues } from 'app/shared/getRoundedValues';
import { CurrentUserContext } from 'app/shared/context/CurrentUserContext';
import classNames from 'classnames';
import { getMeasurement } from 'app/shared/getMeasurement';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useIsMounted } from 'app/shared/hooks/useIsMounted';
import { useQuery } from 'react-query';
import { QuantityUnit } from 'app/shared/domain/value-objects/QuantityUnit';
import { useProductRepository } from '../../products/application/hooks/useProductRepository';
import { ProductId } from '../../products/domain/value-object/ProductId';
import { ReactComponent as IcDelete } from '../../../assets/img/icons/ic-delete-grey.svg';
import { ReactComponent as IcEdit } from '../../../assets/img/icons/ic-edit-grey.svg';
import { ReactComponent as IcCheck } from '../../../assets/img/icons/ic-check.svg';
import { Currency } from '../../shared/Currency';
import { DeleteCartItemModal } from './delete-cart-item-modal';
import CartItem from '../domain/model/CartItem';
import { useCartItemsList } from '../shared/useCartItemList';
import { CartItemProductDetails } from './CartItemProductDetails';
import { Packing } from '../../products/domain/model/Packing';
import { NoStockModal } from '../../product-details/components/NoStockModal';
import { PageSpinner } from '../../shared/components/page-spinner/page-spinner';
import { ErrorCode } from '../../shared/domain/value-objects/ErrorCode';
import { CartInPaymentProcessModal } from '../../product-details/components/CartInPaymentProcessModal';

interface Props {
    item: CartItem;
    cartSummary: boolean,
    onChange: () => void,
    onRemove: () => void,
}

interface FormInput {
    valueInput: number,
}

export const ListItemCart: React.FC<Props> = (
    {
        item,
        cartSummary,
        onChange,
        onRemove,
    },
) => {
    const [removeFromCartIsLoading, setRemoveFromCartIsLoading] = useState(false);
    const [showDeleteItemModal, setShowDeleteItemModal] = useState(false);
    const [isEditing, setIsEditing] = React.useState(false);
    const [packing, setPacking] = React.useState<Packing>();
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const cartHook = useCartItemsList();
    const isMounted = useIsMounted();

    const productRepository = useProductRepository();
    const measureUnitLabel = getMeasurement();

    const { data: queryData } = useQuery({
        queryKey: ['packings', item.productId],
        queryFn: ({ queryKey }) => productRepository.getPacking(queryKey[1] as ProductId),
        onSuccess: (data: Packing) => {
            setPacking(data);
        },
        initialData: packing,
    });

    useEffect(() => {
        if (queryData) {
            setPacking(queryData);
        }
    }, [queryData]);

    const handleHideDeleteItemModal = (): void => {
        if (!isMounted()) {
            return;
        }
        setShowDeleteItemModal(false);
    };

    const handleShowDeleteItemModal = (): void => {
        if (!isMounted()) {
            return;
        }
        setShowDeleteItemModal(true);
    };

    const state = React.useContext(CurrentUserContext);
    const { t } = useTranslation();
    const squareUnits = getSquareMeasurement();
    const [quantityStep, setQuantityStep] = React.useState<number>();
    const [quantityMax, setQuantityMax] = React.useState<number>();
    const [showNoStockUsaModal, setShowNoStockUsaModal] = useState<boolean>(false);
    const [showCartInPaymentProcessModal, setShowCartInPaymentProcessModal] = useState<boolean>(false);
    const { register, handleSubmit, setValue, formState: { errors } } = useForm<FormInput>();

    const onChangeThis = (): void => {
        onChange();
        if (!isMounted()) {
            return;
        }
        setIsSubmitting(false);
        state?.setCartModified(new Date());
    };

    const onRemoveThis = (): void => {
        onRemove();
        if (!isMounted()) {
            return;
        }
        setRemoveFromCartIsLoading(false);
        state?.setCartModified(new Date());
    };

    const classCheck = classNames(
        'delete-icon',
        { 'd-flex': isEditing },
        { 'd-none': !isEditing },
    );

    const classEdit = classNames(
        'delete-icon',
        { 'd-flex': !isEditing },
        { 'd-none': isEditing },
    );

    useEffect(() => {
        const unitName = item?.quantity.unit.value;
        const stepFractional = 0.001;
        const stepUnits = 1;
        setQuantityStep(unitName === QuantityUnit.M2 ? stepFractional : stepUnits);
        setQuantityMax(unitName === QuantityUnit.PALLET ? 100 : undefined);
    }, [item?.quantity.unit.value, item?.quantity.amount]);

    const handleError = (errorCode: ErrorCode): void => {
        setShowDeleteItemModal(false);

        if (errorCode === ErrorCode.CART_NO_STOCK_AVAILABLE_USA) {
            setShowNoStockUsaModal(true);
        }
        if (errorCode === ErrorCode.CART_IN_PAYMENT_PROCESS_STATUS) {
            setShowCartInPaymentProcessModal(true);
        }
    };

    const submitHandler: SubmitHandler<FormInput> = async (data: FormInput): Promise<void> => {
        if (!isMounted()) {
            return;
        }

        if (errors.valueInput) {
            return;
        }

        setIsEditing(false);
        if (data.valueInput === item.quantity.amount) {
            return;
        }
        setIsSubmitting(true);

        let unitString: string = '';
        if (packing?.unit === '0') {
            unitString = t(UNIT_SEND_TO_CART);
        } else if (packing?.unit === '1') {
            unitString = t(M2_SEND_TO_CART);
        }
        state?.modifyCart(
            data.valueInput,
            item.id.value,
            unitString,
            '',
            onChangeThis,
            (errorCode: number): void => {
                handleError(errorCode);
            },
            (): void => {
                setIsSubmitting(false);
            },
        );
    };

    const removeFromCart = async (id: string): Promise<void> => {
        if (!isMounted()) {
            return;
        }
        setRemoveFromCartIsLoading(true);
        cartHook.removeCartItem(id, onRemoveThis, handleError);
    };

    return (
        <>
            <li className="list-item">
                <div className="product-info">
                    {packing ? (
                        <div className="product-image">
                            {packing?.image?.value ? (
                                <img src={packing.image.value} alt="product" />
                            ) : null}
                        </div>
                    ) : <Skeleton circle width={50} height={50} />}

                    <CartItemProductDetails
                        item={item}
                        product={packing}
                        measureUnitLabel={measureUnitLabel}
                        squareUnits={squareUnits}
                    />
                </div>

                <div className="product-info-secondary">
                    <div className="product-actions-column-secondary">

                        <div className="product-tags">
                            {item.discount && item.discount.amount > 0 ? (
                                <div className="product-tag">
                                    <div className="product-discount-cart">
                                        {`${item.discount.amount}% ${t(DISCOUNT)}`}
                                    </div>
                                </div>
                            ) : null}

                            {item.discount2 && item.discount2.amount > 0 ? (
                                <div className="product-tag">
                                    <div className="product-discount-cart">
                                        {`${item.discount2.amount}% ${t(DISCOUNT)}`}
                                    </div>
                                </div>
                            ) : null}
                        </div>

                        {packing ? (
                            <div className="product-line-calc">
                                <span className="product-line-calc-currency">
                                    {packing?.price ? (
                                        <Currency
                                            value={packing?.price.value}
                                            className="import"
                                        />
                                    ) : null}
                                </span>
                                <span className="product-line-calc-separator">
                                    x
                                </span>
                                <span className="product-line-calc-label">
                                    {
                                        packing?.unit === '0' ?
                                            item.quantityInPieces > 1 ? ` ${t(PIECE_UNIT)}` : ` ${t(PIECE_UNIT_SINGLE)}`
                                            : ` ${squareUnits}`
                                    }
                                </span>
                            </div>

                        ) : <Skeleton inline width={100} />}
                    </div>
                    <div className="product-actions-column-secondary">

                        {!cartSummary ? (
                            <div className="product-line-edit-form-wrapper">

                                <form onSubmit={handleSubmit(submitHandler)} className="number-input">
                                    <input
                                        className={`quantity  ${isEditing ? 'changed' : ''}`}
                                        disabled={!isEditing}
                                        type="number"
                                        step={quantityStep}
                                        max={quantityMax}
                                        {...register(
                                            'valueInput',
                                            {
                                                required: true,
                                                valueAsNumber: true,
                                                min: 0,
                                            },
                                        )}
                                    />
                                    <button
                                        type="button"
                                        className="product-line-edit-action"
                                        onClick={(): void => {
                                            setValue('valueInput', item.quantity.amount);
                                            setIsEditing(true);
                                        }}
                                    >
                                        <IcEdit className={classEdit} />
                                    </button>
                                    <button
                                        type="submit"
                                        className="product-line-edit-action"
                                    >
                                        <IcCheck type="submit" className={classCheck} />
                                    </button>
                                </form>
                                <IcDelete onClick={handleShowDeleteItemModal} className="delete-icon" />
                                <DeleteCartItemModal
                                    show={showDeleteItemModal}
                                    handleClose={handleHideDeleteItemModal}
                                    deleteCartItemIsLoading={removeFromCartIsLoading}
                                    removeFromCart={removeFromCart}
                                    idToDelete={item.id.value}
                                />
                            </div>

                        ) : <div>{}</div>}

                        <strong>
                            <Currency
                                value={getRoundedValues(item?.price?.amount)}
                            />
                        </strong>
                    </div>
                </div>

            </li>
            <NoStockModal
                showModal={showNoStockUsaModal}
                closeModal={(): void => {
                    setShowNoStockUsaModal(false);
                }}
            />
            <CartInPaymentProcessModal
                showModal={showCartInPaymentProcessModal}
                closeModal={(): void => {
                    setShowCartInPaymentProcessModal(false);
                }}
            />
            <PageSpinner isBusy={isSubmitting} />
        </>
    );
};
