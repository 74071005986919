import { Integer } from 'app/shared/domain/value-objects/Integer';
import { TencerApiClientSingleton } from 'app/shared/hooks/TencerApiClientSingleton';
import { ApiOrderRepository } from 'app/orders/infrastructure/repository/api/ApiOrderRepository';
import { Packing } from '../../domain/model/Packing';
import { Product } from '../../domain/model/Product';
import { ProductId } from '../../domain/value-object/ProductId';
import { ProductRepository } from '../../infrastructure/repository/tencer-api/ProductRepository';

interface ProductRepositoryMutationsInterface {
    getProduct: (productId: ProductId) => Promise<Product|undefined>;
    getPacking: (productId: ProductId) => Promise<Packing|undefined>;
}

export const useProductRepository = (): ProductRepositoryMutationsInterface => {
    const client = TencerApiClientSingleton.getInstance();
    const repository = new ProductRepository(client);
    const orderRepository = new ApiOrderRepository(client);

    const getProduct = (productId: ProductId): Promise<Product|undefined> => {
        return repository.getProduct(productId.value);
    };

    const getPacking = async (productId: ProductId): Promise<Packing|undefined> => {
        const packings = orderRepository.getPackingCatalog(
            productId,
            new Integer(1),
            new Integer(1),
        );
        const packingsResponse = await packings;
        return packingsResponse[0];
    };

    return {
        getProduct,
        getPacking,
    };

};
