export enum CartStatusValue {
    OPEN = 'OPEN',
    IN_PAYMENT_PROCESS = 'IN_PAYMENT_PROCESS',
}

export class CartStatus {

    private static readonly VALID_STATUSES = new Set(Object.values(CartStatusValue));

    public constructor(
        private readonly _value: CartStatusValue,
    ) {
        if (!CartStatus.isValid(_value)) {
            throw new Error(`Invalid cart status: ${_value}`);
        }
    }

    public get value(): CartStatusValue {
        return this._value;
    }

    public equals(other: CartStatus): boolean {
        return this.value === other.value;
    }

    public isInPaymentProcess(): boolean {
        return this._value === CartStatusValue.IN_PAYMENT_PROCESS;
    }

    private static isValid(value: CartStatusValue): boolean {
        return CartStatus.VALID_STATUSES.has(value);
    }

}
