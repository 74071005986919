import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { PROCESS_ORDER, TOTAL_AMOUNT } from 'i18n/translations/TR';
import { getRoundedValues } from 'app/shared/getRoundedValues';
import { useNavigate } from 'react-router-dom';
import { TencerRoutes } from 'app/shared/tencer-routing/TencerRoutes';
import { Currency } from '../../shared/Currency';
import Cart from '../domain/model/Cart';

interface CartFooterProps {
    cart: Cart
}

export const CartFooter = (props: CartFooterProps): ReactElement => {

    const navigate = useNavigate();

    const goToCartSummary = ():void => {
        navigate(TencerRoutes.CART_SUMMARY);
    };

    const { t } = useTranslation();

    return (
        <div className="cart-footer">
            <div className="container cart-footer-display">
                <div className="total-price-header">
                    <div className="total-price-title">{t(TOTAL_AMOUNT)}</div>
                    <div className="total-price-import">
                        <Currency
                            className="product-price m-0 import"
                            value={getRoundedValues(props.cart.price.amount)}
                        />
                    </div>
                </div>

                <button
                    type="button"
                    onClick={goToCartSummary}
                    className="btn btn-primary"
                    disabled={props.cart.status.isInPaymentProcess()}
                >
                    {t(PROCESS_ORDER)}
                </button>
            </div>
        </div>
    );
};
