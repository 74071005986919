import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { CreditMemoSelector } from './CreditMemoSelector';
import { CreditMemoSelectorOption } from './CreditMemoSelectorOption';
import { getCurrency } from '../../../../../../shared/getCurrency';

export interface CreditMemoFormRegisters {
    creditMemo: string | null;
}

export enum CreditMemoFieldName {
    CREDIT_MEMO = 'creditMemo',
}

interface Props {
    creditMemos: Promise<CreditMemoSelectorOption[]>;
    creditMemoForm: UseFormReturn<CreditMemoFormRegisters>;
}

export const CreditMemoForm: React.FC<Props> = (props: Props) => {
    const creditMemoFormRegister = props.creditMemoForm.register(
        CreditMemoFieldName.CREDIT_MEMO,
        {},
    );
    return (
        <CreditMemoSelector
            options={props.creditMemos}
            creditMemoFormRegister={creditMemoFormRegister}
            currency={getCurrency()}
        />
    );
};
