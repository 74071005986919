import { DiscountResponse } from 'app/shared/infrastructure/tencer-api/dto/responses/DiscountResponse';
import { Item } from './ApiItem';
import { CartShippingResponse } from '../../shared/infrastructure/tencer-api/dto/responses/CartShippingResponse';
import { Price } from '../../orders/model/value-objects/Price';

export interface ApiCartElement {
    items: Item[],
    status: ApiCartStatus
    id: string,
    order_id: string,
    price: { currency: { value: string, name: string}, amount?: number },
    weight: { unit: { value: string, name: string}, amount: number },
    free_stock_at: string,
    client_internal_ref: string,
    delivery_address_code: string,
    discounts: DiscountResponse[],
    credit_memo_id: string | null,
    credit_memo_amount: Price | null,
    shipping: CartShippingResponse | undefined
}

export enum ApiCartStatusName {
    OPEN = 'OPEN',
    IN_PAYMENT_PROCESS = 'IN_PAYMENT_PROCESS',
}

export enum ApiCartStatusValue {
    OPEN = 'open',
    IN_PAYMENT_PROCESS = 'in_payment_process',
}

export type ApiCartStatus =
    | { name: ApiCartStatusName.OPEN; value: ApiCartStatusValue.OPEN }
    | { name: ApiCartStatusName.IN_PAYMENT_PROCESS; value: ApiCartStatusValue.IN_PAYMENT_PROCESS };
