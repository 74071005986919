import { Integer } from 'app/shared/domain/value-objects/Integer';
import { OrderId } from 'app/shared/domain/value-objects/OrderId';
import { UserClientRef } from 'app/shared/domain/value-objects/UserClientRef';
import { TencerApiClient } from 'app/shared/infrastructure/tencer-api/TencerApiClient';
import { OrderResponse } from './responses/OrderResponse';
import { OrdersResponse } from './responses/OrdersResponse';
import { CartId } from '../../../../cart/domain/value-objects/CartId';
import { IOrderRepository as OrderRepositoryInterface } from '../../../model/IOrderRepository';
import Order from '../../../model/Order';
import { OrderFilterCollection } from '../../../model/OrderFilterCollection';
import { ApiProductCatalog } from '../../../../product-catalog-collections/Catalog/shared/domain/ApiProductCatalog';
import { Packing } from '../../../../products/domain/model/Packing';
import { ProductId } from '../../../../products/domain/value-object/ProductId';
import { OrderMapper } from './mappers/OrderMapper';
import { PackingMapper } from './mappers/PackingMapper';
import { PaymentIntentResponse } from '../../../../shared/infrastructure/tencer-api/dto/responses/PaymentIntentResponse';
import { UserCredentialId } from '../../../../shared/domain/value-objects/UserCredentialId';
import { OrderFiltersMapper } from './mappers/OrderFiltersMapper';

export class ApiOrderRepository implements OrderRepositoryInterface {

    private readonly client: TencerApiClient;

    constructor(client: TencerApiClient) {
        this.client = client;
    }

    async getUserOrders(
        filters: OrderFilterCollection,
        page: number,
        results: number,
        confirmed: boolean,
    ): Promise<Order[]> {
        const filtersDTO = OrderFiltersMapper.toWs(filters);
        const response:OrdersResponse = await this.client.getUserOrders(filtersDTO, page, results, confirmed);
        const orders:OrderResponse[] = response.order_list;
        return orders.map((order: OrderResponse) => OrderMapper.fromResponse(order));
    }

    async getOrder(orderId: OrderId): Promise<Order> {
        const response:OrderResponse = await this.client.getOrder(orderId);
        return OrderMapper.fromResponse(response);
    }

    async getOrderFile(orderId: OrderId, orderFileId: string): Promise<Blob> {
        return this.client.getOrderFile(orderId, orderFileId);
    }

    async addOrderFile(orderId: OrderId, file: File): Promise<void> {
        return this.client.postOrderFile(orderId, file);
    }

    async getPackingCatalog(productId: ProductId, page: Integer, results: Integer): Promise<Packing[]> {
        return this.client.getPackingCatalog([productId.value], results.value, page.value)
            .then(
                (response) => response
                    .map((catalog: ApiProductCatalog) => PackingMapper.fromApiProductCatalog(catalog)),
            );
    }

    async addClientRef(cartId: CartId, clientRef: UserClientRef): Promise<void> {
        return this.client.putCartClientRef(cartId.value, clientRef.value);
    }

    async paymentIntent(orderId: OrderId, applicantId: UserCredentialId): Promise<PaymentIntentResponse> {
        return this.client.paymentIntent(orderId.value, applicantId.value);
    }

}
