export class Locality {

    private readonly _value: string;

    public constructor(value: string) {
        this._ensureIsNotUndefined(value);
        this._value = value;
    }

    public get value(): string {
        return this._value;
    }

    private _ensureIsNotUndefined(value: string): void {
        if (value === undefined || value === '') {
            throw new Error('Locality cannot be undefined ');
        }
    }

}
