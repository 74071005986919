import { ApiCartStatus, ApiCartStatusName } from '../../../../shared/ApiCartItem';
import { CartStatus, CartStatusValue } from '../../../../domain/value-objects/CartStatus';

export default class CartStatusMapper {

    public static fromResponse(apiStatus: ApiCartStatus): CartStatus {
        switch (apiStatus.name) {
            case ApiCartStatusName.OPEN:
                return new CartStatus(CartStatusValue.OPEN);

            case ApiCartStatusName.IN_PAYMENT_PROCESS:
                return new CartStatus(CartStatusValue.IN_PAYMENT_PROCESS);

            default:
                throw new Error(`Unknown API cart status: ${apiStatus}`);
        }
    }

}
