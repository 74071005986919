import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { BASE_URL } from 'app/constants/urlConstants';
import { tokenConfig, url } from 'app/shared/context/config';
import { OrderId } from 'app/shared/domain/value-objects/OrderId';
import { PostShippingAddressRequest } from 'app/account/infrastructure/api/dto/request/post-shipping-address-request.DTO';
import { OrdersResponse } from 'app/orders/infrastructure/repository/api/responses/OrdersResponse';
import { OrderResponse } from 'app/orders/infrastructure/repository/api/responses/OrderResponse';
import { ApiCollection } from '../../../home/infrastructure/api/response/ApiCollection';
import { ApiProduct } from '../../../home/infrastructure/api/response/ApiProduct';
import { ApiCartElement } from '../../../cart/shared/ApiCartItem';
import { ShippingAddress } from '../../../orders/model/ShippingAddress';
import {
    ShippingAddressDefaultResponse,
    ShippingAddressesResponse,
} from '../../../orders/model/responses/ShippingAddressesResponse';
import PageBanner from '../../../page-banner/model/PageBanner';
import { ShippingAddressMapper } from '../../../orders/infrastructure/repository/api/mappers/ShippingAddressMapper';
import { ApiProductCatalog } from '../../../product-catalog-collections/Catalog/shared/domain/ApiProductCatalog';
import { ApiProductDetail } from '../../../product-details/shared/domain/ApiProductDetail';
import PageBannerMapper from '../../../page-banner/infraestructure/repository/api/data-mapper/PageBannerMapper';
import { ApiCollectionDetail } from '../../../collection-details/components/shared/ApiCollectionDetails';
import { ApiFilterFields } from '../../../product-catalog-collections/Catalog/components/shared/ApiFilterFields';
import { ErpUserDTO } from '../../../account/infrastructure/api/dto/erp-user.DTO';
import { GetErpUserRequestDTO } from '../../../account/infrastructure/api/dto/request/get-erp-user-request.DTO';
import { GetErpUserContactsResponseDTO } from '../../../account/infrastructure/api/dto/get-erp-user-contacts-response.DTO';
import { AddErpUserContactRequestDTO } from '../../../account/infrastructure/api/dto/request/add-erp-user-contact-request.DTO';
import { GetShippingAddressesResponseDTO } from '../../../account/infrastructure/api/dto/response/get-shipping-addresses-response.DTO';
import { GetShippingAddressesRequestDTO } from '../../../account/infrastructure/api/dto/request/get-shipping-addresses-request.DTO';
import { GetDefaultShippingAddressRequestDTO } from '../../../account/infrastructure/api/dto/request/get-default-shipping-address-request.DTO';
import { GetErpUserResponseDTO } from '../../../account/infrastructure/api/dto/get-erp-user-response.DTO';
import { PatchErpUserPasswordDTO } from '../../../account/infrastructure/api/dto/request/patch-erp-user-password.DTO';
import { MarketingCatalogResponse } from './dto/responses/MarketingCatalogResponse';
import { PageBannerResponse } from './dto/responses/PageBannerResponse';
import { TokenResponse } from './dto/responses/TokenResponse';
import { OrderFilterRequest } from '../../../orders/infrastructure/repository/api/requests/OrderFilterRequest';
import { UserCountryResponse } from './dto/responses/UserCountryResponse';
import { TencerAuthServiceInstance } from '../../tencer-routing/tencer-auth-route/TencerAuthService';
import { WarehouseLocationMapper } from '../../../product-details/infrastructure/api/data-mapper/warehouseLocationMapper';
import { WarehouseLocation } from '../../../product-details/shared/domain/WarehouseLocation';
import { LoadingOrderDTO } from '../../../loading-order/infrastructure/api/dto/loading-order.DTO';
import { LoadingOrderSlotDTO } from '../../../loading-order/infrastructure/api/dto/loading-order-slot.DTO';
import { GetLoadingOrderPackagesResponseDTO } from '../../../loading-order/infrastructure/api/dto/response/get-loading-order-packages-response.DTO';
import { PostLoadingOrderRequestDTO } from '../../../loading-order/infrastructure/api/dto/request/post-loading-order-request.DTO';
import { GetOrdersToLoadResponseDTO } from '../../../loading-order/infrastructure/api/dto/response/get-orders-to-load-response.DTO';
import { PostAnalyticsEventRequest } from './dto/requests/post-analytics-event-request';
import { GetLiftGateRequestDTO } from '../../../cartSummary/infrastructure/repository/api/dto/request/GetLiftGateRequest.DTO';
import { GetTransportPriceRequestDTO } from '../../../cartSummary/infrastructure/repository/api/dto/request/GetTransportPriceRequest.DTO';
import { ShippingAddressResponse } from '../../../orders/model/responses/ShippingAddressResponse';
import { PaymentIntentResponse } from './dto/responses/PaymentIntentResponse';
import { PostLoadingOrderLinesRequestDTO } from '../../../loading-order/infrastructure/api/dto/request/post-loading-order-lines-request.DTO';
import { SearchType } from '../../domain/value-objects/searchType';
import { ICreditMemoResponse } from './dto/responses/ICreditMemoResponse';
import { PatchCartShippingRequest } from './dto/requests/PatchCartShippingRequest';
import { CreditMemoRequest } from './dto/requests/CreditMemoRequest';
import { PaymentTermsResponse } from './dto/responses/PaymentTermsResponse';
import { GetCouriersServiceRequest } from '../../../cartSummary/infrastructure/repository/api/dto/request/GetCouriersServiceRequest';
import { GetCouriersRequest } from '../../../cartSummary/infrastructure/repository/api/dto/request/GetCouriersRequest';
import { CourierResponse } from '../../../cartSummary/infrastructure/repository/api/dto/response/CourierResponse';
import { CourierServiceResponse } from '../../../cartSummary/infrastructure/repository/api/dto/response/CourierServiceResponse';
import { PostRequestAccessRequest } from './dto/requests/PostRequestAccessRequest';
import { InvoicesResponse } from '../../../invoices/infrastructure/repository/api/response/InvoicesResponse';
import { InvoiceResponse } from '../../../invoices/infrastructure/repository/api/response/InvoiceResponse';
import { InvoiceFilterRequest } from '../../../orders/infrastructure/repository/api/requests/InvoiceFilterRequest';
import { GetWowCouriersRequest } from '../../../cartSummary/infrastructure/repository/api/dto/request/GetWowCouriersRequest';
import { PickHandleResponse } from '../../../cartSummary/infrastructure/repository/api/dto/response/PickHandleResponse';

export class TencerApiClient {

    private instance: AxiosInstance;

    private _loginURL: string;

    constructor(loginURL: string) {
        this._loginURL = loginURL;
        this.instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
            },
        });

        this.instance.interceptors.request.use(
            (config) => {
                const customConfig = config;
                const token = TencerAuthServiceInstance.getToken();
                if (token) {
                    customConfig.headers = config.headers || {};
                    customConfig.headers.Authorization = `Bearer ${token}`;
                }
                return config;
            },
            (error) => {
                return Promise.reject(error);
            },
        );

        this.instance.interceptors.response.use(
            (res) => {
                return res;
            },
            async (err) => {
                const originalConfig = err.config;
                if (originalConfig.url === url(BASE_URL, '/api/login_check')
                    || originalConfig.url === url(BASE_URL, '/api/token/refresh')) {
                    TencerAuthServiceInstance.clear();
                    if (originalConfig.url === url(BASE_URL, '/api/token/refresh')) {
                        window.location.pathname = this._loginURL;
                    }

                    return Promise.reject(err);
                }

                const refreshToken = TencerAuthServiceInstance.getRefreshToken();

                if (!refreshToken) {
                    TencerAuthServiceInstance.setRefreshToken('bad-token');
                    return Promise.reject(err);
                }
                if (err.response.status === 401) {
                    const response = await this.instance.post(url(BASE_URL, '/api/token/refresh'), {
                        refresh_token: refreshToken,
                    });
                    TencerAuthServiceInstance.authenticate(response.data.token);
                    TencerAuthServiceInstance.setRefreshToken(response.data.refresh_token);
                    return this.instance(originalConfig);
                }

                return Promise.reject(err);
            },
        );
    }

    public async postLogin(username: string, password: string): Promise<TokenResponse> {
        const endpoint = url(BASE_URL, '/api/login_check');
        const options = tokenConfig();
        const response = this.instance.post(
            endpoint,
            {
                username,
                password,
            },
            options,
        );
        return (await response).data;
    }

    public async postCartProducts(
        quantity: number,
        quantityUnits: string,
        productId: string,
        forced: boolean,
        warehouse: string | undefined,
    ): Promise<AxiosResponse<void>> {
        const endpoint = url(BASE_URL, `/api/customer/my-cart/products/${productId}`);

        const options = tokenConfig();
        options.params = {};
        if (forced) {
            options.params.force = forced;
        }
        return this.instance.post<void>(
            endpoint,
            {
                quantity,
                quantity_units: quantityUnits,
                location: warehouse,
            },
            options,
        );

    }

    public async putDefaultShippingAddress(
        erpCode: string,
        userCredentialId: string,
        shippingAddressCode: string,
    ): Promise<void> {
        const shippingAddressEndpoint = encodeURIComponent(
            shippingAddressCode.trim() === '' ? ' ' : `${shippingAddressCode}`,
        );

        const endpoint = url(BASE_URL, `/api/customer/users/${erpCode}/shipping-addresses/${shippingAddressEndpoint}`);
        const options = tokenConfig();
        await this.instance.put<void>(
            endpoint,
            {
                user_credential_id: userCredentialId,
            },
            options,
        );
    }

    public async getPageBanner(pageId: string): Promise<PageBanner | null> {
        const options = tokenConfig();
        options.headers = options.headers || {};
        options.headers['Accept-Language'] = TencerAuthServiceInstance.getLanguage() || 'en';
        const response = this.instance.get<PageBannerResponse[]>(
            url(BASE_URL, `/api/customer/pages/${pageId}/banners`),
            options,
        );

        const data = (await response).data;

        if (data.length > 0) {
            return PageBannerMapper.fromResponse(data[0]);
        }

        return null;
    }

    public async getMyCart(): Promise<ApiCartElement> {
        const response = await this.instance.get(url(BASE_URL, '/api/customer/my-cart'), tokenConfig());
        return response.data;
    }

    public async getCollections(page?: number, order?: string, results?: number): Promise<ApiCollection[]> {
        const options = tokenConfig();
        options.params = {
            page: page || 1,
            order,
            results: results || 5,
        };
        const endpoint = '/api/customer/collection/catalog';
        const response = await this.instance.get(url(BASE_URL, endpoint), options);
        return response.data;
    }

    public async getCollectionDetails(collectionId: string): Promise<ApiCollectionDetail> {
        const options = tokenConfig();
        options.params = {
            collection: collectionId,
        };
        const response = await this.instance.get(
            url(BASE_URL, '/api/customer/collection/detail'),
            options,
        );
        return response.data;
    }

    public async getCatalogSearch(
        textSearch: string,
        page: number,
        results: number,
    ): Promise<ApiProductCatalog[]> {
        const options = tokenConfig();
        options.params = {
            textSearch,
            page,
            results,
        };
        const response = await this.instance.get<ApiProductCatalog[]>(
            url(BASE_URL, '/api/customer/packing/search'),
            options,
        );

        return response.data.map<ApiProductCatalog>((product: ApiProductCatalog) => {
            return {
                description: product.description,
                enabled: product.enabled,
                product: product.product,
                box: product.box,
                boxMeter: product.boxMeter,
                boxMeterL: product.boxMeterL,
                boxPiece: product.boxPiece,
                boxWeight: product.boxWeight,
                default: product.default,
                code: `${product.product}-${product.box}`,
                palletBox: product.palletBox,
                palletPlane: product.palletPlane,
                heights: product.heights,
                palletPiece: product.palletPiece,
                palletMeter: product.palletMeter,
                unit: product.unit,
                format: product.format,
                family: product.family,
                type: product.type,
                collection: product.collection,
                collectionId: product.collectionId,
                outlet: product.outlet,
                paste: product.paste,
                price: product.price,
                image: product.image,
                tile: product.tile,
                stockEntryDate: product.stockEntryDate,
            } as ApiProductCatalog;
        });
    }

    public async getCatalogByUrl(getUrl: string): Promise<ApiProduct[]> {
        const response = await this.instance.get(url(BASE_URL, getUrl), tokenConfig());
        return response.data;
    }

    public async getOutletCatalog(results: number, page?: number): Promise<ApiProduct[]> {
        const endpoint = '/api/customer/packing/outlet';
        const options = tokenConfig();
        options.params = {
            results,
            page: page || 1,
        };
        const response = await this.instance.get(url(BASE_URL, endpoint), options);
        return response.data;
    }

    public async getTrendingProducts(results: number, page?: number): Promise<ApiProductCatalog[]> {

        const endpoint = '/api/customer/packing/top-trending';
        const options = tokenConfig();
        options.params = {
            results,
            page: page || 1,
        };

        const response = await this.instance.get(url(BASE_URL, endpoint), options);
        return response.data;
    }

    public async putCartClientRef(cartId: string, clientRef: string): Promise<void> {
        const options = tokenConfig();
        const endpoint = url(BASE_URL, `/api/customer/carts/${cartId}/client-internal`);
        await this.instance.put<void>(endpoint, {
            ref: clientRef,
        }, options);
    }

    public async getUserOrders(
        filters: OrderFilterRequest,
        page: number,
        results: number,
        confirmed: boolean,
    ): Promise<OrdersResponse> {
        const options = tokenConfig();
        const defaultParams = { page, results, confirmed };
        options.params = Object.assign(
            defaultParams,
            ...filters.request.map((x) => (
                {
                    [x.name]: String(x.value),
                }
            )),
        );
        const response = this.instance.get<OrdersResponse>(url(BASE_URL, '/api/customer/orders'), options);
        return (await response).data;
    }

    public async getOrder(orderId: OrderId): Promise<OrderResponse> {
        const options = tokenConfig();
        const orderIdParam = orderId.value;
        const response = this.instance.get<OrderResponse>(
            url(BASE_URL, `/api/customer/orders/${orderIdParam}`),
            options,
        );

        return (await response).data;
    }

    public async getOrderFile(orderId: OrderId, orderFileId: string): Promise<Blob> {
        const endpoint = `${'/api/customer/orders/'}${orderId.value}${'/files/'}${orderFileId}`;
        const options = tokenConfig();
        options.responseType = 'blob';
        const response = this.instance.get<Blob>(
            url(BASE_URL, endpoint),
            options,
        );
        return (await response).data;
    }

    public async postOrderFile(orderId: OrderId, file: File): Promise<void> {
        const endpoint = `${'/api/customer/orders/'}${orderId.value}${'/files'}`;
        const options = tokenConfig();
        const formData = new FormData();
        formData.append('name', file.name);
        formData.append('order-file', file);
        await this.instance.post(url(BASE_URL, endpoint), formData, options);
    }

    public async getShippingAddresses(
        erpCode: string,
        limit?: number,
        description?: string,
        excludeDefault?: boolean,
    ): Promise<ShippingAddress[]> {
        const options = tokenConfig();
        options.params = {
            results: limit,
            description,
            excludeDefault,
        };
        const response = this.instance.get<ShippingAddressesResponse>(
            url(BASE_URL, `/api/users/${erpCode}/shipping-addresses`),
            options,
        );

        const data = (await response).data.shipping_addresses;
        return data.map((props) => {
            return ShippingAddressMapper.fromResponse(props);
        });

    }

    public async getDefaultShippingAddress(userCredentialId: string): Promise<ShippingAddressDefaultResponse> {
        const options = tokenConfig();
        const response = this.instance.get<ShippingAddressDefaultResponse>(
            url(BASE_URL, `/api/user/user-credentials/${userCredentialId}/shipping-addresses/default`),
            options,
        );

        return (await response).data;
    }

    public async getShippingAddress(shippingAddressId: string): Promise<ShippingAddressResponse> {
        const options = tokenConfig();
        const response = this.instance.get<ShippingAddressResponse>(
            url(BASE_URL, `/api/customer/shipping-addresses/${shippingAddressId}`),
            options,
        );

        return (await response).data;
    }

    public async putMyCartItems(
        units: string,
        amount: number,
        cartItemId: string,
        force?: boolean,
    ): Promise<void> {
        const options = tokenConfig();
        options.params = {
            force,
        };

        const endpoint = url(BASE_URL, `/api/customer/my-cart/items/${cartItemId}`);
        await this.instance.put<void>(endpoint, {
            units,
            amount,
        }, options);
    }

    public async postMyCartCheckout(): Promise<void> {
        const options = tokenConfig();
        options.headers = options.headers || {};
        options.headers['Accept-Language'] = TencerAuthServiceInstance.getLanguage() || 'en';
        const checkoutEndpoint = url(BASE_URL, '/api/customer/my-cart/checkout');

        return (await this.instance.post<void>(
            checkoutEndpoint,
            {},
            options,
        )).data;
    }

    public async getMyCartProforma(orderId: string): Promise<Blob> {
        const options = tokenConfig();
        options.responseType = 'blob';
        return (await this.instance.get<Blob>(
            url(BASE_URL, `/api/customer/orders/${orderId}/proforma`),
            options,
        )).data;
    }

    public async getPackingCatalog(
        productIds: string[],
        results: number,
        page: number,
        collectionId?: string,
        paste?: string,
        brand?: string,
        availableStock?: boolean,
        textSearch?: string,
    ): Promise<ApiProductCatalog[]> {
        const options = tokenConfig();
        const defaultParams = {
            page,
            results,
            collectionId,
            paste,
            brand,
            availableStock,
            textSearch,
        };

        options.params = defaultParams;
        if (productIds.length > 0) {
            options.params = Object.assign(
                defaultParams,
                {
                    productId: productIds,
                    searchType: productIds.length > 0 ? undefined : SearchType.CATALOG,
                },
            );
        }
        const response = await this.instance.get<ApiProductCatalog[]>(
            url(BASE_URL, '/api/customer/packing/catalog'),
            options,
        );

        return response.data.map<ApiProductCatalog>((product: ApiProductCatalog) => {
            return {
                description: product.description,
                enabled: product.enabled,
                product: product.product,
                box: product.box,
                boxMeter: product.boxMeter,
                boxMeterL: product.boxMeterL,
                boxPiece: product.boxPiece,
                boxWeight: product.boxWeight,
                default: product.default,
                code: `${product.product}-${product.box}`,
                palletBox: product.palletBox,
                palletPlane: product.palletPlane,
                heights: product.heights,
                palletPiece: product.palletPiece,
                palletMeter: product.palletMeter,
                unit: product.unit,
                format: product.format,
                family: product.family,
                type: product.type,
                collection: product.collection,
                collectionId: product.collectionId,
                outlet: product.outlet,
                paste: product.paste,
                price: product.price,
                image: product.image,
                tile: product.tile,
                stockEntryDate: product.stockEntryDate,
            } as ApiProductCatalog;
        });
    }

    public async getProduct(productId: string): Promise<ApiProductDetail> {
        const options = tokenConfig();
        options.params = { product: productId };
        const response = await this.instance.get(
            url(BASE_URL, '/api/customer/packing/detail'),
            options,
        );
        return response.data;
    }

    public async getMarketingCollection(): Promise<MarketingCatalogResponse> {
        const options = tokenConfig();
        const endpoint = url(BASE_URL, '/api/customer/collections/marketing');

        const response = await this.instance.get<MarketingCatalogResponse>(
            endpoint,
            options,
        );

        return response.data;
    }

    public async getErpUser(
        getUserRequestDTO: GetErpUserRequestDTO,
    ): Promise<ErpUserDTO> {
        const options = tokenConfig();
        options.params = getUserRequestDTO;
        const endpoint = '/api/customer/erpuser/find-by-id';

        const response = await this.instance.get<undefined,
        AxiosResponse<GetErpUserResponseDTO>>(url(BASE_URL, endpoint), options);

        return response.data.customer;
    }

    public async getPickHandle(): Promise<number> {
        const options = tokenConfig();
        const endpoint = '/api/customer/shipment/pick-handle';

        const response = await this.instance.get<undefined,
        AxiosResponse<PickHandleResponse>>(url(BASE_URL, endpoint), options);

        return response.data.price;
    }

    public async patchErpUserPassword(
        patchErpUserPasswordDto: PatchErpUserPasswordDTO,
    ): Promise<string> {
        const options = tokenConfig();
        const endpoint = '/api/customer/userCredentials/password';

        const response = await this.instance.patch<undefined,
        AxiosResponse<string>>(url(BASE_URL, endpoint), patchErpUserPasswordDto, options);
        return response.data;
    }

    public async postPasswordReset(
        password: string,
        token: string | null,
        lang?: string,
    ): Promise<void> {
        const options = tokenConfig(lang);
        options.headers = options.headers || {};
        options.headers['Accept-Language'] = TencerAuthServiceInstance.getLanguage() || 'en';
        const endpoint = '/api/user/password/reset';

        await this.instance.post(url(BASE_URL, endpoint), { email: password }, options);
    }

    public async postPasswordChange(
        password: string,
        token: string | null,
        lang?: string,
    ): Promise<void> {
        const options = tokenConfig(lang);
        options.headers = options.headers || {};
        options.headers['Accept-Language'] = TencerAuthServiceInstance.getLanguage() || 'en';
        const endpoint = '/api/user/password/reset/';
        await this.instance.post(url(BASE_URL, endpoint, token), { password }, options);
    }

    public async fetchGetShippingAddresses(
        getShippingAddressesRequestDto: GetShippingAddressesRequestDTO,
    ): Promise<GetShippingAddressesResponseDTO> {
        const options = tokenConfig();
        const endpoint = `/api/users/${getShippingAddressesRequestDto.erpCode}/shipping-addresses`;

        const response = await this.instance.get<undefined,
        AxiosResponse<GetShippingAddressesResponseDTO>>(url(BASE_URL, endpoint), options);

        return response.data;
    }

    public async postShippingAddress(
        request: PostShippingAddressRequest,
    ): Promise<ShippingAddressResponse> {
        const options = tokenConfig();
        const endpoint = '/api/customer/shipping-addresses';

        const response = await this.instance.post(url(BASE_URL, endpoint), request, options);

        return response.data;
    }

    public async putShippingAddress(
        shippingAddressId: string,
        body: PostShippingAddressRequest,
    ): Promise<ShippingAddressResponse> {
        const options = tokenConfig();
        const endpoint = `/api/customer/shipping-addresses/${shippingAddressId}`;

        const response = await this.instance.put(
            url(BASE_URL, endpoint),
            body,
            options,
        );

        return response.data;
    }

    public async deleteShippingAddress(shippingAddressId: string): Promise<void> {
        const options = tokenConfig();
        const endpoint = `/api/customer/shipping-addresses/${shippingAddressId}`;

        await this.instance.delete(
            url(BASE_URL, endpoint),
            options,
        );
    }

    public async fetchGetDefaultShippingAddress(
        getDefaultShippingAddressDto: GetDefaultShippingAddressRequestDTO,
    ): Promise<ShippingAddressDefaultResponse> {
        const options = tokenConfig();
        const endpoint =
            `/api/user/user-credentials/${getDefaultShippingAddressDto.userCredentialId}/shipping-addresses/default`;

        const response = await this.instance.get<undefined,
        AxiosResponse<ShippingAddressDefaultResponse>>(url(BASE_URL, endpoint), options);

        return (await response).data;
    }

    public async getErpUserContacts(): Promise<GetErpUserContactsResponseDTO> {
        const options = tokenConfig();
        const endpoint = '/api/customer/contacts';

        const response = await this.instance.get<undefined,
        AxiosResponse<GetErpUserContactsResponseDTO>>(url(BASE_URL, endpoint), options);

        return (await response).data;
    }

    public async fetchDeleteErpUserContact(email: string): Promise<string> {
        const endpoint = `${'/api/customer/contacts/'}${email}`;
        const options = tokenConfig();

        const response = await this.instance.delete<undefined,
        AxiosResponse<string>>(url(BASE_URL, endpoint), options);

        return (await response).data;
    }

    public async fetchAddErpUserContact(addErpUserContactRequestDto: AddErpUserContactRequestDTO): Promise<string> {
        const endpoint = '/api/customer/contacts';
        const options = tokenConfig();
        options.params = addErpUserContactRequestDto;

        const response = await this.instance.put<undefined,
        AxiosResponse<string>>(url(BASE_URL, endpoint), addErpUserContactRequestDto, options);

        return (await response).data;
    }

    public async getFilterValues(): Promise<ApiFilterFields> {
        const options = tokenConfig();
        const endpoint = url(BASE_URL, '/api/customer/filter/values');

        const response = await this.instance.get<ApiFilterFields>(
            endpoint,
            options,
        );

        return response.data;
    }

    public async getUserCountries(): Promise<UserCountryResponse[]> {

        const options = tokenConfig();
        const endpoint = '/api/customer/countries-by-province';

        const response = await this.instance.get<UserCountryResponse[]>(
            url(BASE_URL, endpoint),
            options,
        );

        return response.data;

    }

    public async getWarehousesLocation(): Promise<WarehouseLocation[]> {
        const options = tokenConfig();
        const response = await this.instance.get(
            url(BASE_URL, '/api/customer/warehouse-locations'),
            options,
        );

        return WarehouseLocationMapper.toDomain(response.data);
    }

    public async getLoadingOrders(
        page: number,
        results: number,
    ): Promise<LoadingOrderDTO[]> {
        const options = tokenConfig();
        options.params = { page, results };
        const response = await this.instance.get(
            url(BASE_URL, '/api/customer/loading-orders'),
            options,
        );

        return response.data;
    }

    public async getLoadingOrderPDF(loadingOrderId: string): Promise<Blob> {
        const options = tokenConfig();
        options.responseType = 'blob';
        options.headers = {
            Accept: 'application/pdf',
        };
        return (await this.instance.get<Blob>(
            url(BASE_URL, `/api/customer/loading-orders/${loadingOrderId}`),
            options,
        )).data;
    }

    public async getOrdersToLoad(code: string): Promise<GetOrdersToLoadResponseDTO> {
        const options = tokenConfig();
        options.params = {
            'shipping-address-code': code,
        };
        const response = await this.instance.get<GetOrdersToLoadResponseDTO>(
            url(BASE_URL, '/api/customer/orders-to-load'),
            options,
        );

        return response.data;
    }

    public async getLoadingOrderDates(): Promise<LoadingOrderSlotDTO> {
        const options = tokenConfig();
        const response = await this.instance.get(
            url(BASE_URL, '/api/customer/loading-order-slots'),
            options,
        );

        return response.data;
    }

    public async getLoadingOrderPackages(
        request: PostLoadingOrderLinesRequestDTO[],
    ): Promise<GetLoadingOrderPackagesResponseDTO> {
        const options = tokenConfig();

        const response = await this.instance.post(
            url(BASE_URL, '/api/customer/loading-order-parcels'),
            request,
            options,
        );

        return response.data;
    }

    public async postLoadingOrder(
        request: PostLoadingOrderRequestDTO,
    ): Promise<void> {
        const options = tokenConfig();
        const endpoint = '/api/customer/loading-orders';

        await this.instance.post(url(BASE_URL, endpoint), request, options);
    }

    public async getLoadingOrder(loadingOrderId: string): Promise<LoadingOrderDTO> {
        const options = tokenConfig();

        return (await this.instance.get<LoadingOrderDTO>(
            url(BASE_URL, `/api/customer/loading-orders/${loadingOrderId}`),
            options,
        )).data;
    }

    public async getLiftGatePrice(): Promise<GetLiftGateRequestDTO> {
        const options = tokenConfig();
        const response = await this.instance.get<GetLiftGateRequestDTO>(
            url(BASE_URL, '/api/customer/shipment/lift-gate'),
            options,
        );

        return response.data;
    }

    public async getTransportPrice(): Promise<GetTransportPriceRequestDTO> {
        const options = tokenConfig();
        const response = await this.instance.get<GetTransportPriceRequestDTO>(
            url(BASE_URL, '/api/customer/shipments-freight-rates'),
            options,
        );

        return response.data;
    }

    public async postAnalyticsEvent(request: PostAnalyticsEventRequest): Promise<void> {
        const options = tokenConfig();
        const response = await this.instance.post(
            url(BASE_URL, '/api/customer/events'),
            request,
            options,
        );

        return response.data;
    }

    public async getCouriers(request: GetCouriersRequest): Promise<CourierResponse[]> {
        const options = tokenConfig();
        options.params = request;
        const response = await this.instance.get<CourierResponse[]>(
            url(BASE_URL, '/api/couriers'),
            options,
        );

        return response.data;
    }

    public async getCourierServices(request: GetCouriersServiceRequest): Promise<CourierServiceResponse[]> {
        const options = tokenConfig();
        const response = await this.instance.get<CourierServiceResponse[]>(
            url(BASE_URL, `/api/couriers/${request.code}/services`),
            options,
        );

        return response.data;
    }

    public async getWowCouriers(request: GetWowCouriersRequest): Promise<CourierResponse[]> {
        const options = tokenConfig();
        options.params = request;
        const response = await this.instance.get<CourierResponse[]>(
            url(BASE_URL, '/api/customer/wow-couriers'),
            options,
        );

        return response.data;
    }

    public async paymentIntent(orderId: string, applicantId: string): Promise<PaymentIntentResponse> {

        const response = await this.instance.post(
            url(BASE_URL, `/api/customer/orders/${orderId}/payment`),
            {
                orderId,
                applicantId,
            },
            tokenConfig(),
        );
        return response.data;
    }

    public async getCreditMemos(): Promise<ICreditMemoResponse[]> {
        const response = await this.instance.get(
            url(BASE_URL, '/api/customer/credit-memo'),
            tokenConfig(),
        );
        return response.data;
    }

    public async patchMyCartShipping(request: PatchCartShippingRequest): Promise<void> {
        const response = await this.instance.patch(
            url(BASE_URL, '/api/customer/my-cart/shipping'),
            request,
            tokenConfig(),
        );
        return response.data;
    }

    public async patchMyCartPayment(request: CreditMemoRequest): Promise<void> {
        const response = await this.instance.patch(
            url(BASE_URL, '/api/customer/my-cart/payment'),
            request,
            tokenConfig(),
        );
        return response.data;
    }

    public async getErpUserPaymentTerms(): Promise<PaymentTermsResponse> {
        const response = await this.instance.get(
            url(BASE_URL, '/api/customer/credit'),
            tokenConfig(),
        );
        return response.data;
    }

    public async postRequestAccess(request: PostRequestAccessRequest): Promise<void> {
        const response = await this.instance.post(
            url(BASE_URL, '/api/request-access'),
            request.payload,
            tokenConfig(),
        );
        return response.data;
    }

    public async getInvoiceList(
        page: number,
        results: number,
        filters: InvoiceFilterRequest,
    ): Promise<InvoicesResponse> {

        const options = tokenConfig();
        const defaultParams = {
            page,
            results,
        };

        options.params = Object.assign(
            defaultParams,
            ...filters.request.map((x) => (
                {
                    [x.name]: String(x.value),
                }
            )),
        );

        const response = await this.instance.get(
            url(BASE_URL, '/api/customer/invoices'),
            options,
        );

        return response.data;
    }

    public async getInvoice(invoiceNumber: string): Promise<InvoiceResponse> {
        const endpoint = url(BASE_URL, `/api/customer/invoices/${invoiceNumber}`);
        const response = await this.instance.get(endpoint);
        return response.data;
    }

}
