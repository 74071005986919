import { Integer } from 'app/shared/domain/value-objects/Integer';
import { OrderLineResponse } from '../responses/OrderLineResponse';
import { Discount } from '../../../../model/value-objects/Discount';
import { OrderLineIdx } from '../../../../model/value-objects/OrderLineIdx';
import { Price } from '../../../../model/value-objects/Price';
import ProductQuantity from '../../../../model/value-objects/ProductQuantity';
import { ToneId } from '../../../../model/value-objects/ToneId';
import { Weight } from '../../../../model/value-objects/Weight';
import { ProductId } from '../../../../../products/domain/value-object/ProductId';
import OrderLine from '../../../../model/OrderLine';
import { OrderLineStatus } from '../../../../model/value-objects/OrderLineStatus';

export class OrderLineMapper {

    public static fromResponse(lineResponse: OrderLineResponse): OrderLine {
        return new OrderLine(
            new OrderLineIdx(lineResponse.idx),
            new Weight(lineResponse.weight_gross),
            new Price(lineResponse.price),
            new ProductId(lineResponse.product_id),
            new ProductQuantity(
                lineResponse.quantity.amount,
                lineResponse.quantity.unit,
            ),
            lineResponse.description,
            new ToneId(lineResponse.tone),
            new Integer(lineResponse.pallets ?? 0),
            new Integer(lineResponse.boxes_total ?? 0),
            new Integer(lineResponse.boxes ?? 0),
            new Weight(lineResponse.weight_net ?? 0),
            new Discount(lineResponse.discount_special ?? 0),
            new Discount(lineResponse.discount_special2 ?? 0),
            lineResponse.es_pico,
            new Price(lineResponse.net),
            new Price(lineResponse.gross),
            lineResponse.delivery_date,
            new OrderLineStatus(lineResponse.status),
            lineResponse.warehouse_location ? {
                code: lineResponse.warehouse_location.code,
                name: lineResponse.warehouse_location.name,
            } : null,
        );
    }

}
