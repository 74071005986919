import {
    PaymentElement, useElements,
    useStripe,
} from '@stripe/react-stripe-js';
import React, { FormEvent } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { StripePaymentElementChangeEvent } from '@stripe/stripe-js';
import { Price } from '../../../../../orders/model/value-objects/Price';
import { Currency } from '../../../../../shared/Currency';
import { TencerRoutes } from '../../../../../shared/tencer-routing/TencerRoutes';
import i18n from '../../../../../../i18n';
import { LanguageCode } from '../../../../../account/domain/value-objects/language';
import './checkoutForm.scss';
import { CActionButton } from '../../../../../shared/components/CActionButton/CActionButton';

type Props = {
    amount: Price | undefined,
    onReset?: () => void,
};

export const CheckoutForm = (props: Props): React.ReactElement => {

    const stripe = useStripe();
    const elements = useElements();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [isValid, setIsValid] = React.useState(false);
    const [isPaying, setIsPaying] = React.useState(false);

    const handleSubmit = async (event: FormEvent): Promise<void> => {
        setIsPaying(true);
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();
        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }
        const okAbsoluteURL = `${window.location.origin}${TencerRoutes.CHECKOUT_OK}`;

        const result = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: okAbsoluteURL,
            },
        });

        if (result.error) {
            navigate(TencerRoutes.CHECKOUT_KO);
        }

        setIsPaying(false);
    };

    const onReady = async (): Promise<void> => {
        elements?.update({
            appearance: {
                theme: 'flat',
                rules: {
                    '.Input': {
                        border: 'none',
                        borderBottom: '1px solid #808080',
                        borderRadius: 'none',
                        backgroundColor: 'transparent',
                        paddingLeft: '0px',
                    },
                    '.Input:focus': {
                        outline: 'none',
                        borderBottom: '1px solid #C49D68',
                        boxShadow: 'none',
                    },
                },
            },
            currency: 'usd',
            locale: i18n.language as LanguageCode,
        });
    };

    const onChange = (event: StripePaymentElementChangeEvent): void => {
        setIsValid(event.complete);
    };

    return (
        <form onSubmit={handleSubmit}>
            <PaymentElement
                onReady={onReady}
                onChange={onChange}
                options={
                    {
                        fields: {
                            billingDetails: {
                                address: {},
                            },
                        },
                        terms: {
                            card: 'never',
                        },
                    }
                }
            />

            {props.amount ? (
                <CActionButton
                    text={
                        (
                            <>
                                <span>{t('CREDIT_CARD_PAYMENT_PAY_BUTTON')}</span>
                                <Currency
                                    value={props.amount?.amount}
                                    className="checkout-form-action-payment-amount"
                                />
                            </>
                        )
                    }
                    type="submit"
                    disabled={!isValid}
                    isBusy={isPaying}
                />
            ) : <Skeleton />}
            <button
                type="button"
                className="btn btn-primary-outline action-button"
                onClick={props.onReset}
            >
                <span className="text">{t('CANCEL')}</span>
            </button>
        </form>
    );
};
