import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { DISCOUNT } from '../../../../../../i18n/translations/TR';
import 'react-loading-skeleton/dist/skeleton.css';
import { getRoundedValues } from '../../../../../shared/getRoundedValues';
import { Currency } from '../../../../../shared/Currency';
import { useOrigin } from '../../../../../shared/hooks/useOrigin';
import { Quantity } from '../../../../../shared/components/Quantity';
import { useProductRepository } from '../../../../../products/application/hooks/useProductRepository';
import { ProductId } from '../../../../../products/domain/value-object/ProductId';
import { Packing } from '../../../../../products/domain/model/Packing';
import OrderLine from '../../../../model/OrderLine';
import { OrderLineProductInfo } from './productInfo/OrderLineProductInfo';

interface LineItemProp{
    line:OrderLine,
}
export const OrderLineItem = (
    {
        line,
    } :LineItemProp,
):React.ReactElement => {
    const { t } = useTranslation();
    const productRepository = useProductRepository();
    const [packing, setPacking] = React.useState<Packing>();
    const origin = useOrigin();

    useQuery({
        queryKey: ['packings', line.productId],
        queryFn: ({ queryKey }) => productRepository.getPacking(queryKey[1] as ProductId),
        onSuccess: (data: Packing) => {
            setPacking(data);
        },
    });

    const { data: queryData } = useQuery({
        queryKey: ['packings', line.productId],
        queryFn: ({ queryKey }) => productRepository.getPacking(queryKey[1] as ProductId),
        onSuccess: (data: Packing) => {
            setPacking(data);
        },
        initialData: packing,
    });

    useEffect(() => {
        if (queryData) {
            setPacking(queryData);
        }
    }, [queryData]);

    const productActionsRender = (
        <div className="detail-price">
            <div className="product-actions">
                <div className="product-price">
                    {(!line.discountSpecial || line.discountSpecial.value <= 0) ? null :
                        (
                            <div className="product-discount-cart order-line-discount-special">
                                {`${line.discountSpecial.value}% ${t(DISCOUNT)}`}
                            </div>
                        )}
                </div>
                <div className="product-price ">
                    {(!line.discountSpecial2 || line.discountSpecial2.value <= 0) ? null :
                        (
                            <div className="product-discount-cart order-line-discount-special2">
                                {`${line.discountSpecial2.value}% ${t(DISCOUNT)}`}
                            </div>
                        )}
                </div>
            </div>
            <div className="product-actions">
                <div className="total-price">
                    <strong>
                        <Currency
                            value={line.price?.amount ? line.price.amount : 0}
                            className="import"
                        />
                    </strong>
                    {' x '}
                    <Quantity
                        value={line.quantity.amount ? line.quantity.amount : 0}
                        label={line.quantity.unitLabel(origin)}
                    />
                </div>
                <div>
                    <strong>
                        <Currency
                            value={
                                getRoundedValues(line.net?.amount ? line.net.amount : 0)
                            }
                            className="import"
                        />
                    </strong>
                </div>
            </div>
        </div>
    );

    return (
        <li className="list-item">
            <OrderLineProductInfo
                line={line}
                packing={packing}
                getPackingCatalogIsLoading={!packing}
            />
            {productActionsRender}
        </li>
    );
};
