import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Cart from 'app/cart/domain/model/Cart';
import { UseFormReturn } from 'react-hook-form';
import {
    CART,
    CREDIT_MEMO_APPLIED_LABEL,
    ORDER_SUMMARY_TOTAL_PRODUCTS,
    PICK_HANDLE,
    STOCK_TIME_REMAINING,
    SUMMARY, SUMMARY_FREIGHT_SUBTITLE_USA,
    SUMMARY_TOTAL_WEIGHT_SUBTITLE_USA,
    TOTAL_AMOUNT,
    TOTAL_CART_DISCOUNTS_APPLIED_LABEL,
    TOTAL_WEIGHT,
    TRANSPORT_LABEL,
} from '../../../../../../i18n/translations/TR';
import { COWeight } from '../../../../../shared/components/CO-weight';
import { getRoundedValues } from '../../../../../shared/getRoundedValues';
import { Currency } from '../../../../../shared/Currency';
import { useOrigin } from '../../../../../shared/hooks/useOrigin';
import { Origin } from '../../../../../shared/domain/value-objects/Origin';
import { ETransportType } from '../../../../../orders/model/value-objects/transport-type';
import { DiscountsSummary } from '../../../../../orders/application/components/discountsSummary/DiscountsSummary';
import { DiscountsSummaryLayout } from '../../../../../orders/application/components/discountsSummary/DiscountsSummaryLayout';
import {
    CreditMemoForm,
    CreditMemoFormRegisters,
} from './CreditMemoForm/CreditMemoForm';
import { ICreditMemo } from '../../../../../orders/model/CreditMemo';
import { Spinner } from '../../../../../shared/Spinner';
import { Price } from '../../../../../orders/model/value-objects/Price';
import { EnvEnum } from '../../../../../shared/domain/value-objects/EnvEnum';

interface SummaryProps {
    cart: Cart;
    cartWeight: number;
    hasPickHandle: boolean;
    pickHandlePrice: Price;
    timer: string | undefined;
    watchConsolidated: boolean;
    creditMemos: Promise<ICreditMemo[]>;
    watchTransportResponsible: ETransportType;
    creditMemoForm: UseFormReturn<CreditMemoFormRegisters>;
}

export const Summary = (
    {
        cart,
        timer,
        cartWeight,
        creditMemos,
        hasPickHandle,
        creditMemoForm,
        pickHandlePrice,
        watchConsolidated,
        watchTransportResponsible,
    }: SummaryProps,
): React.ReactElement => {
    const { t } = useTranslation();
    const origin = useOrigin();
    const [total, setTotal] = React.useState<number>();

    const handleSummaryTotal = (): void => {
        setTotal(cart.totalAmount.amount);
    };

    useEffect(() => {
        handleSummaryTotal();
    }, [cart, cart.totalAmount.amount, cart.transport]);

    return (
        <>
            <h5 className="card-wow-title">{t(SUMMARY)}</h5>
            <div className="card-box-summary">
                <span className="cart-content-subtitle">{t(CART)}</span>
                {
                    process.env.REACT_APP_ENV !== EnvEnum.PROD && process.env.REACT_APP_ENV !== EnvEnum.STAGE ? (
                        <CreditMemoForm
                            creditMemos={creditMemos}
                            creditMemoForm={creditMemoForm}
                        />
                    ) : null
                }
                <ul className="card-box-summary-list-container">
                    <li className="card-box-summary-list-item">
                        <div className="title">
                            {t(ORDER_SUMMARY_TOTAL_PRODUCTS)}
                        </div>
                        <div className="value">{cart.items.length}</div>
                    </li>
                    <li className="card-box-summary-list-item">
                        <div className="title">
                            {t(TOTAL_WEIGHT)}
                        </div>
                        <div className="value">
                            <COWeight
                                value={getRoundedValues(cartWeight)}
                                className="import"
                            />
                        </div>
                        {
                            origin === Origin.USA ? (
                                <div className="subtitle">
                                    {t(SUMMARY_TOTAL_WEIGHT_SUBTITLE_USA)}
                                </div>
                            ) : null
                        }
                    </li>
                    <li className="card-box-summary-list-item">
                        <div className="title">
                            {t(STOCK_TIME_REMAINING)}
                        </div>
                        <div className="value">{timer}</div>
                    </li>
                    {
                        cart.discounts.length > 0 ? (
                            <>
                                <li className="card-box-summary-list-item">
                                    <div className="title">
                                        {t(TOTAL_CART_DISCOUNTS_APPLIED_LABEL)}
                                    </div>
                                    <div className="value">
                                        <span>-</span>
                                        <Currency value={cart.discountsAmount()} className="import" />
                                    </div>
                                </li>
                                <li className="card-box-summary-list-item cart-discounts">
                                    <DiscountsSummary
                                        gross={cart.brutePrice()}
                                        discounts={cart.discounts}
                                        layout={DiscountsSummaryLayout.FULL}
                                    />
                                </li>
                            </>
                        ) : null
                    }
                    {origin === Origin.USA ? (
                        <>
                            {watchTransportResponsible === ETransportType.WOW &&
                            !!cart.transport?.courier?.price?.amount &&
                            cart.transport?.courier?.price?.amount > 0 ?
                                (
                                    <li className="card-box-summary-list-item">
                                        <p className="title">
                                            {t(TRANSPORT_LABEL)}
                                        </p>
                                        <p className="amount">
                                            <span>+</span>
                                            <Currency
                                                className="import"
                                                value={cart.transport.courier.price.amount}
                                            />
                                        </p>
                                        <div className="subtitle">
                                            {t(SUMMARY_FREIGHT_SUBTITLE_USA)}
                                        </div>
                                    </li>
                                ) : null}
                            {!watchConsolidated && hasPickHandle && pickHandlePrice.amount > 0 ? (
                                <li className="card-box-summary-list-item">
                                    <p className="title">
                                        {t(PICK_HANDLE)}
                                    </p>
                                    <p className="amount">
                                        <span>+</span>
                                        <Currency
                                            className="import"
                                            value={pickHandlePrice.amount}
                                        />
                                    </p>
                                </li>
                            ) : null}
                            {cart.creditMemo && cart.creditMemo.amount > 0 ? (
                                <li className="card-box-summary-list-item">
                                    <p className="title">
                                        {t(CREDIT_MEMO_APPLIED_LABEL)}
                                    </p>
                                    <p className="amount">
                                        <span>-</span>
                                        <Currency
                                            className="import"
                                            value={cart.creditMemoValueApplied.amount}
                                        />
                                    </p>
                                </li>
                            ) : null}
                        </>
                    ) : null}
                    <li className="card-box-summary-list-item summary-total-price">
                        <p className="summary-total-price-title">
                            {t(TOTAL_AMOUNT)}
                        </p>
                        <div className="summary-total-price-import">
                            {
                                total !== undefined ?
                                    (
                                        <Currency value={total} className="import" />
                                    )
                                    : <Spinner classCss="summary-total-price-spinner" />
                            }
                        </div>
                    </li>
                </ul>
            </div>
        </>
    );
};
