import { OrderFilesArrayResponse } from './OrderFilesArrayResponse';
import { OrderLineResponse } from './OrderLineResponse';
import { ShippingAddressResponse } from '../../../../model/responses/ShippingAddressResponse';
import { OrderShipmentLineResponse } from './OrderShipmentLineResponse';
import { DiscountResponse } from '../../../../../shared/infrastructure/tencer-api/dto/responses/DiscountResponse';
import { ICreditMemoResponse } from '../../../../../shared/infrastructure/tencer-api/dto/responses/ICreditMemoResponse';
import { PickHandleOrderLineResponse } from './PickHandleOrderLineResponse';

export interface OrderResponse {
    package_tracking_no: string | null;
    shipping_agent_code: string | null;
    id: string
    company: string
    year: number
    shipping: string
    lines: OrderLineResponse[]
    order_date: Date
    user_client_ref: string
    files: OrderFilesArrayResponse | undefined
    weight_gross: number | null
    weight_net: number | null
    net: number | null
    gross: number | null
    status: OrderStatusResponse | null
    shipping_address: ShippingAddressResponse
    order_shipment_line: OrderShipmentLineResponse | null
    discounts: DiscountResponse[]
    credit_memo: ICreditMemoResponse | null
    pick_handle_order_line: PickHandleOrderLineResponse | null
    pallet_handling_fee_line: OrderLineResponse | null
}

export enum OrderStatusResponse {
    ORDERED = 'PEDIDO',
    IN_PREPARATION = 'EN PREPARACION',
    READY = 'PREPARADO',
    CHARGED = 'CARGADO',
    INVOICED = 'FACTURADO',
    RELEASED = 'Released',
    OPEN = 'Open',
}
