import React from 'react';
import { useTranslation } from 'react-i18next';
import { FieldError, UseFormRegisterReturn } from 'react-hook-form';
import { SHIPPING_ADDRESS_LABEL_CITY, SHIPPING_ADDRESS_LABEL_LOCALITY } from '../../../../../../i18n/translations/TR';
import { useOrigin } from '../../../../../shared/hooks/useOrigin';
import { Origin } from '../../../../../shared/domain/value-objects/Origin';

interface ShippingAddressLocalityFieldProps {
    localityRegister: UseFormRegisterReturn
    errorField: FieldError | undefined

}

export const ShippingAddressLocalityField = (
    {
        localityRegister,
        errorField,
    }: ShippingAddressLocalityFieldProps,
): React.ReactElement => {
    const origin = useOrigin();
    const { t } = useTranslation();
    return (
        <div className="form-field locality-field">
            <label htmlFor="locality" className="form-label is-required">
                {origin === Origin.USA ? t(SHIPPING_ADDRESS_LABEL_CITY) : t(SHIPPING_ADDRESS_LABEL_LOCALITY)}
            </label>
            <input
                type="text"
                id="locality"
                maxLength={30}
                className="form-control"
                {...localityRegister}
            />
            {errorField ? (
                <div className="error-input-form">{errorField.message}</div>
            ) : null}
        </div>
    );
};
