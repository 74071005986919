import React from 'react';
import { Spinner } from 'app/shared/Spinner';
import { ServerErrorMessage } from 'app/shared/ServerErrorMessage';
import { useProductDetailsList } from './shared/domain/ProductDetailList';
import { DataSheet } from './components/DataSheet';
import { WarehouseInfoTable } from './components/WarehouseInfoTable';
import { DownloadsBanner } from '../shared/DownloadBanner';
import './ProductDetailsScreen.scss';

export const ProductDetailsScreen: React.FC = () => {
    const url = window.location.pathname;
    const id = url.substring(url.lastIndexOf('/') + 1);

    let serverErrorMessage: boolean = false;
    const response = useProductDetailsList(id);
    const loading = response?.loading;
    const detail = response?.data;
    const error = response?.error;

    if (error) {
        serverErrorMessage = true;
    }

    return (
        <div>
            {!loading ?
                (
                    <div>
                        {!serverErrorMessage ?
                            (
                                <div>
                                    {detail ?
                                        (
                                            <>
                                                <div className="row bg-white">
                                                    <div className="col-xl-8">
                                                        {/* Sección ficha técnica de producto */}
                                                        <DataSheet
                                                            detail={detail!}
                                                            refreshProductData={response.fetchGetProduct}
                                                            outOfStockWarehousesLocations={
                                                                response.outOfStockWarehousesLocations
                                                            }
                                                        />
                                                    </div>

                                                    <div className="col-xl-4">
                                                        {/* Sección tabla de disponibilidad */}
                                                        {
                                                            detail?.lotInfo ? (
                                                                <WarehouseInfoTable
                                                                    detail={detail!}
                                                                    outOfStockWarehousesLocations={
                                                                        response.outOfStockWarehousesLocations
                                                                    }
                                                                />
                                                            )
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                </div>

                                                { /* Sección descargas */ }
                                                <DownloadsBanner />
                                            </>
                                        )
                                        : null}
                                </div>
                            ) : <ServerErrorMessage />}
                    </div>
                )
                : <Spinner classCss="header-section-spinner" /> }
        </div>
    );
};
