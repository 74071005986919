import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ORDERS } from 'i18n/translations/TR';

import { SectionHeaderOrders } from 'app/shared/SectionHeaderOrders';
import { Spinner } from 'app/shared/Spinner';
import InfiniteScroll from 'react-infinite-scroll-component';
import { FilterOrdersModal } from 'app/orders/application/list/filtersModal/FilterOrdersModal';
import { OrderFilterPills } from './filtersPills/OrderFilterPills';
import { useOrdersList } from '../hooks/useOrdersList';
import { OrderListTable } from './table/orderListTable';

export const OrdersScreen: React.FC = () => {
    const { t } = useTranslation();
    const [show, setShow] = useState<boolean>(false);
    const handleShow = (): void => setShow(true);
    const handleClose = (): void => setShow(false);

    const ordersList = useOrdersList();

    const loaderElement = (<Spinner classCss="spinner-infinite_scroll text-center" />);

    return (
        <div className="screen">
            <div className="screen-orders">
                <section className="orders">
                    <div className="container">
                        { ordersList.orders.length === 0 && ordersList.isLoading ? (
                            loaderElement
                        ) : (
                            <InfiniteScroll
                                dataLength={ordersList.orders.length}
                                next={ordersList.fetch}
                                hasMore={ordersList.paginator.hasMore}
                                loader={loaderElement}
                                endMessage={<p className="end-message">{t('NO_MORE_RESULTS')}</p>}
                            >
                                <SectionHeaderOrders title={t(ORDERS)} handleShow={handleShow} />
                                <div className="filter-tag-container">
                                    <OrderFilterPills filters={ordersList.orderFilters.filters} />
                                </div>

                                <OrderListTable
                                    ordersList={ordersList}
                                />
                            </InfiniteScroll>
                        )}
                    </div>
                </section>
                <FilterOrdersModal show={show} onHide={handleClose} onApplyFilters={ordersList.handleApplyFilters} />
            </div>
        </div>
    );
};
