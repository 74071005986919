import React, { BaseSyntheticEvent, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { LOADING, ORDER_ATTACH_FILE, ORDER_ATTACH_FILE_INPUT_LABEL, SEND } from '../../../../../i18n/translations/TR';
import { CActionButton } from '../../../../shared/components/CActionButton/CActionButton';
import { ModalError } from '../../../../shared/ModalError';
import Order from '../../../model/Order';
import { useOrderRepository } from '../../hooks/useOrderRepository';

interface OrderAttachAdderProps {
    order: Order;
    show: boolean;
    onAttach: () => void;
}

export const OrderAttachAdder = (props: OrderAttachAdderProps): React.ReactElement => {
    const { t } = useTranslation();

    const [show, setShow] = React.useState(false);
    const [selectedFile, setSelectedFile] = useState();
    const [errorMessage, setErrorMessage] = useState<string>();

    const orderRepository = useOrderRepository();

    useEffect(() => {
        setShow(props.show);
    }, []);

    const onAttachBtnClick = (): void => {
        setShow(!show);
    };

    const changeHandler = (event: BaseSyntheticEvent): void => {
        setSelectedFile(event?.target?.files[0]);
    };

    const handleSubmission = (): void => {

        if (!selectedFile) {
            return;
        }
        orderRepository.addOrderFileMutation.mutate(
            {
                file: selectedFile,
                orderId: props.order.id,
            },
            {
                onSuccess: () => {
                    setShow(false);
                    setSelectedFile(undefined);
                    props.onAttach();
                },
                onError: () => {
                    setErrorMessage(t(ORDER_ATTACH_FILE_INPUT_LABEL));
                    setSelectedFile(undefined);
                },
            },
        );
    };

    return (
        <>
            <button type="button" className="btn" onClick={onAttachBtnClick}>
                {t(ORDER_ATTACH_FILE)}
            </button>
            <Modal
                show={show}
                onHide={(): void => setShow(false)}
                className="modal fade"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <h5 className="modal-title">{t(ORDER_ATTACH_FILE)}</h5>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div>
                            <label
                                htmlFor="order-attach-file"
                                className="form-label"
                            >
                                {t(ORDER_ATTACH_FILE_INPUT_LABEL)}
                            </label>
                            <input
                                id="order-attach-file"
                                type="file"
                                name="file"
                                onChange={changeHandler}
                                className="form-control"
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div>
                        <CActionButton
                            onClick={handleSubmission}
                            text={t(SEND)}
                            textIsBusy={t(LOADING)}
                            isBusy={orderRepository.addOrderFileMutation.isLoading}
                            disabled={!selectedFile}
                        />
                    </div>
                </Modal.Footer>
            </Modal>
            <ModalError
                show={!!errorMessage}
                onHide={(): void => setErrorMessage(undefined)}
                message={errorMessage}
            />
        </>
    );
};
